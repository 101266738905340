import React from 'react';
import { WarningSection, MintSection } from '../components';
const Home = () => {
  return (
    <>
      <WarningSection />
      <MintSection />
    </>
  )
}

export default Home;