import React from 'react';
import { TokenInfo, MyNFTs } from '../components';
const MyWallet = () => {
  return (
    <>
      <TokenInfo />
      <MyNFTs />
    </>
  )
}

export default MyWallet;