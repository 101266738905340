import React from 'react';
import { DescriptionSection, WorkshopSection } from '../components';
const Workshop = () => {
  return (
    <>
      <DescriptionSection />
      <WorkshopSection />
    </>
  )
}

export default Workshop;